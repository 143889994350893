<template>
  <section>
    <div class="xa-cell__box">在极飞门店:{{ isApp }}</div>
    <div class="xa-cell__box">调用：{{ message }}</div>
    <div class="xa-cell__box">结果：{{ result }}</div>
    <div class="xa-cell__box xa-cell btns-box">
      <van-button type="info" @click="onClick('setAppMenuBtns')"
        >setAppMenuBtns</van-button
      >
      <van-button type="info" @click="onClick('toScanQRCode')"
        >toScanQRCode</van-button
      >
      <van-button type="info" @click="onClick('toGetAccessToken')"
        >toGetAccessToken</van-button
      >
      <van-button type="info" @click="onClick('onOpen')">open</van-button>
    </div>
  </section>
</template>
<script>
import appController, { isApp } from '@/controllers/app-android'
import { Button } from 'vant'
export default {
  components: {
    VanButton: Button
  },
  data() {
    return {
      isApp,
      message: '',
      result: ''
    }
  },
  methods: {
    onClick(type) {
      if (this[type] && typeof this[type] === 'function') this[type]()
      window.console.log(appController)
    },
    onOpen() {
      this.message = 'open'
      this.result = ''
      appController.open('https://www.xa.com/')
    },
    setAppMenuBtns() {
      this.message = 'setAppMenuBtns'
      this.result = ''
      appController.setAppMenuBtns(['按钮1', '按钮2'])
    },
    toScanQRCode() {
      this.message = 'toScanQRCode'
      this.result = ''
      appController.toScanQRCode().then(result => {
        this.result = result
      })
    },
    toGetAccessToken() {
      this.message = 'toGetAccessToken'
      this.result = ''
      appController.toGetAccessToken().then(data => {
        this.result = JSON.stringify(data)
      })
    }
  },
  mounted() {
    appController.addSubscriber('onAppOpenMenuBtnsCompleted', index => {
      this.result = index
    })
    appController.addSubscriber('onAccessTokenCompleted', data => {
      this.message = 'APP主动通知：onAccessTokenCompleted'
      this.result = typeof data === 'string' ? data : JSON.stringify(data)
    })
  },
  beforeDestroy() {
    appController.clearSubscriber('onAppOpenMenuBtnsCompleted')
  }
}
</script>
<style scoped lang="scss">
.btns-box {
  flex-wrap: wrap;
  justify-content: space-between;
  & > button {
    margin-bottom: 16px;
  }
}
</style>
